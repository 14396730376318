import router from '../router'
import VueTk from '@moke/vue-tk'
const rulers = [
  {
    match: '*',
    handler: async function (route, next) { // 路由鉴权方法
      next(true)
    },
    // 禁止访问的跳转地址，优先级低于handler中返回的地址
    fallbackUrl: '/common/login'
  }
]

VueTk.tkRouterAuth.init(rulers, router)
