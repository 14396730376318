import Vue from 'vue'
import Vuex from 'vuex'
import actions from './actions'
import mutations from './mutations'
Vue.use(Vuex)
const store = new Vuex.Store({
  state: {
    sessionToken: null,
    user: null, // 用户信息
    active: 0,
  },
  mutations,
  actions
})
export default store
