<template>
  <div class="banner-container relative wow fadeInDown" :class="bgImg ? 'high' : 'low'" :style="{backgroundImage: 'url(' + bgImg + ')','background-repeat': 'no-repeat','background-position': 'center','backgroundSize': 'cover',}">
    <div class="banner-title w-full absolute bottom-0 text-center font-XQ font-normal text-white">{{title}}</div>
  </div>
</template>

<script>
// import iconFont from '@/components/iconFont'

export default {
  name: 'steps',
  data () {
    return {
      userinput: '', // 双向绑定数据
      isicon: true // 判断inconfont图标是否显示

    }
  },
  components: {
    // iconFont,
  },
  watch: {
    userinput (val, oldval) {
      if (val) {
        this.isicon = false
      } else {
        this.isicon = true
      }
    }
  },
  mounted () {

  },
  props: {
    title: String,
    bgImg: String,
  },
  methods: {
  }
}
</script>

<style lang='scss' scoped>
@import '@/assets/scss/mixin1700.scss';

.container{
  position: absolute;
}
.banner-container{
}
.high{
  height: 834px;
  @include hamburger {
    height: 300px;
  }
}
.low{
  height: 399px;
  @include hamburger {
    height: 162px;
  }
}
.banner-title{
  line-height: 0.9;
  font-size: 10.5vw;
  // font-size: 200px;
  @include hamburger {
    font-size: 70px;
  }
}

</style>
