<template>
  <div id="app" >
    <!-- <topHeader /> -->
    <router-view></router-view>
    <!-- <botFoot /> -->
  </div>
</template>

<script>
window.onload = function () {
  document.addEventListener('touchstart', function (event) {
    if (event.touches.length > 1) {
      event.preventDefault()
    }
  })
  var lastTouchEnd = 0
  document.addEventListener('touchend', function (event) {
    var now = (new Date()).getTime()
    if (now - lastTouchEnd <= 300) {
      event.preventDefault()
    }
    lastTouchEnd = now
  }, false)
}
// import topHeader from '@/components/topHeader'
// import botFoot from '@/components/botFoot'

export default {
  name: 'app',
  data () {
    return {
      // components: [
      //   {
      //     name: 'toast',
      //     component: 'tk-toast'
      //   }
      // ]
    }
  },
  components: {
    // topHeader,
    // botFoot
  },
  mounted () {

  },
  methods: {

  }
}
</script>

<style lang="scss">
@mixin wide-screen {
  @media screen and (min-width: 1700px) {
    @content;
  }
}
@mixin pc {
  @media screen and (min-width: 768px) and (max-width: 1700px) {
    @content;
  }
}
@mixin sp {
  @media screen and (max-width: 559px) {
    @content;
  }
}
@mixin hamburger {
  @media screen and (max-width: 768px) {
    @content
  }
}

#app{
  background-attachment:fixed;
}

</style>
