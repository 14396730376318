<template>
  <div class="">
    <div v-if="status === 'success'">
      <div class="bg"></div>
      <!-- 头部header -->
      <div v-if="showHeader">
        <topHeader :active='active' />
      </div>
      <!-- 内容 -->
      <!-- 页面success -->
      <template>
        <div class="content">
          <slot/>
        </div>
      </template>
      <!-- 是否显示底部 -->
      <template v-if="showFooter">
        <botFoot />
      </template>
    </div>
    <!-- 页面waiting -->
    <div  v-if="status !== 'success'">
      <div class="loader"></div>
      <loading v-if="status === 'requestEnd'" @funEnd='funEnd' ></loading>
    </div>
  </div>
</template>

<script>
import topHeader from '@/components/topHeader'
import botFoot from '@/components/botFoot'
import loading from '@/components/loading'

export default {
  name: 'Containers',
  components: {
    topHeader,
    botFoot,
    loading
  },
  props: {
    active: {
      type: Number,
      default: 0
    },
    showHeader: {
      type: Boolean,
      default: true
    },
    status: {
      type: String,
      default: 'success'
    },
    showFooter: {
      // 是否显示底部
      type: Boolean,
      default: true
    },
  },
  data () {
    return {
      currentPage: null,
      pulldownrefreshEvents: {},
      reachbottomEvents: {},
      activePath: '/',
      refreshTriggered: false, // 下拉刷新状态
      loadMoreStatus: 'loaded' // 'loading' 'loaded' 'error' 'nomore
    }
  },
  mounted () {
  },

  methods: {
    funEnd () {
      this.$emit('funEnd')
    },

  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/mixin1700.scss';

.bg{
  position: fixed;
  z-index: -1;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: #CA4938 url(../assets/images/texture.png) repeat repeat bottom center;
}
.loader{
  position: fixed;
  z-index: 9999;
  left: 0;
  top: 0;
  width: 100%;
  height: 3px;
  background: rgba(30, 69, 108, 0.2);
  &::after{
  content: '';
  height: 100%;
  width: 60px;
  position: absolute;
  left: 0;
  top: 0;
  background-color: #1E456C;
  animation: move 2s linear infinite;
  }
}
@keyframes move{
  0%{
    left: -5%;
  }
  100%{
    left: 105%;
  }
}
.content{
  @include sp {
    width: 100%;
    padding: 0 15px !important;
  }
  @include hamburger {
    width: 100%;
    padding: 0 15px !important;
  }
}
</style>
