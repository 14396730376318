export default {
  date (isoString) {
    let date = new Date(isoString)
    return [[Number((date.getFullYear().toString())), date.getMonth() + 1, date.getDate()].join('-')].join(' ').replace(/(?=\b\d\b)/g, '0')
  },
  dateYear (isoString) {
    let date = new Date(isoString)
    return [[date.getFullYear(), [date.getMonth() + 1, date.getDate() + '日'].join('月')].join('年')].join(' ').replace(/(?=\b\d\b)/g, '0')
  },
  dateMounth (isoString) {
    let date = new Date(isoString)
    return [[date.getMonth() + 1, date.getDate() + '日'].join('月')].join(' ').replace(/(?=\b\d\b)/g, '0')
  },
  limitedTimeMount (isoString) {
    let date = new Date(isoString)
    return [[date.getMonth() + 1, date.getDate() + '日'].join('月')].join(' ').replace(/(?=\b\d\b)/g, '0')
  },
  datetime (isoString) {
    let date = new Date(isoString)
    return [[date.getFullYear(), date.getMonth() + 1, date.getDate()].join('-'),
      [date.getHours(), date.getMinutes()].join(':')
    ].join(`     `).replace(/(?=\b\d\b)/g, '0')
  },
  YMD (isoString) {
    let date = new Date(isoString)
    return [[date.getFullYear(), date.getMonth() + 1, date.getDate()].join('-'),
    ].join(`     `).replace(/(?=\b\d\b)/g, '0')
  },

  /**
   * 点赞数、访问数
   * @date: 2020/4/11
   */
  transform (value) {
    let newValue = ['', '', '']
    let fr = 1000
    let num = 3
    while (value / fr >= 1) {
      fr *= 10
      num += 1
    }
    if (num <= 4) { // 千
      newValue[1] = '千'
      newValue[0] = parseInt(value / 1000) + ''
    } else if (num <= 8) { // 万
      const text1 = parseInt(num - 4) / 3 > 1 ? '千万' : '万'
      const fm = text1 === '万' ? 10000 : 10000000
      newValue[1] = text1
      newValue[0] = (value / fm).toFixed(1) + ''
    } else if (num <= 16) { // 亿
      let text1 = (num - 8) / 3 > 1 ? '千亿' : '亿'
      text1 = (num - 8) / 4 > 1 ? '万亿' : text1
      text1 = (num - 8) / 7 > 1 ? '千万亿' : text1
      let fm = 1
      if (text1 === '亿') {
        fm = 100000000
      } else if (text1 === '千亿') {
        fm = 100000000000
      } else if (text1 === '万亿') {
        fm = 1000000000000
      } else if (text1 === '千万亿') {
        fm = 1000000000000000
      }
      newValue[1] = text1
      newValue[0] = parseInt(value / fm) + ''
    }
    if (value < 1000) {
      newValue[1] = ''
      newValue[0] = value + ''
    }
    return newValue.join('')
  }
}
