import Vue from 'vue'
import router from './router'
import VueTk from '@moke/vue-tk'
import Antd from 'ant-design-vue/es'
import App from './App.vue'
import 'ant-design-vue/dist/antd.css'
import './components/global.js'
import config from './config'
import './assets/scss/font.scss'
import './assets/scss/main.scss'
import store from '../src/store/index'
import './assets/css/tailwindcss.css'

import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.min.js'

import VueAwesomeSwiper from 'vue-awesome-swiper'
// require styles
import 'swiper/dist/css/swiper.css'

import 'animate.css'
import Containers from './components/Containers'

import MetaInfo from 'vue-meta-info'
Vue.use(MetaInfo)
Vue.use(Containers)
// import wow from 'wowjs'
// import animated from 'animate.css'
// Vue.prototype.$wow = wow

// Vue.use(animated)
Vue.use(VueAwesomeSwiper)

Vue.config.productionTip = false
Vue.use(Antd)

let plusReady = new Promise((resolve) => {
  if (!VueTk.tkUa.isPlus) resolve()
  if (window.plus) resolve()
  document.addEventListener('plusready', resolve, false)
})

async function init () {
  for (let pluginName of config.plugins) {
    let plugin = require('./plugins/' + pluginName)
    if (plugin && plugin.default && typeof plugin.default === 'function') {
      await plugin.default()
    }
  }
  await plusReady
  new Vue({
    router,
    store,
    render: h => h(App),
  }).$mount('#app')
}
init()
