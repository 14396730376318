<template>
  <div class="headline-container w-full wow fadeInDown" :style="{color: color}">
    <div class="headline-english relative" :class="align ? align : 'center' ">
      <div class="tag font-XQ">
        {{english}}
        <iconFont class="icon absolute pc-show" :class="align === 'left' ? 'icon-left' : 'icon-center' " icon='wavyline' :color='color' :size='51' ></iconFont>
        <iconFont class="icon icon-mb absolute mb-show" :class="align === 'left' ? 'icon-left' : 'icon-center' " icon='wavyline' :color='color' :size='40' ></iconFont>
      </div>
    </div>
    <div class="titles text-40 font-normal mt-24" :class="align ? align : 'center'">{{title}}</div>
  </div>
</template>

<script>
import iconFont from '@/components/iconFont'

export default {
  name: 'steps',
  data () {
    return {

    }
  },
  components: {
    iconFont,
  },
  mounted () {

  },
  props: {
    title: String,
    english: String,
    color: String,
    align: String
  },
  methods: {
  }
}
</script>

<style lang='scss' scoped>
@import '@/assets/scss/mixin1700.scss';

.headline-container{
  .headline-english{
    .tag{
      position: relative;
      display: inline-block;
      @include hamburger {
        font-size: 12px;
      }
    }
    .icon{
      top: 50%;
    }
    .icon-mb{
      top: 5px;
    }
  }
  .titles{
    @include hamburger {
      font-size: 18px !important;
      margin-top: 11px !important;
    }
  }
}

.left{
  text-align: left;
}
.center{
  text-align: center;
}
.icon-left{
  left: 0;
  transform: unset;
}
.icon-center{
  left: 50% !important;
  transform: translateX(-50%);
}

</style>
