<template>
  <div class="steps h-full">
    <div class="container h-full bg-gray-400 flex flex-col items-center text-24 font-XQ" :style="{color: color}" >
      <span class="crcl leading-8">{{num}}</span>
      <span class="w-15 h-15"></span>
      <span class="bar" :style="{'background': color }"></span>
      <span class="w-15 h-15"></span>
      <span class="text-landscape">{{title}}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'steps',
  data () {
    return {
      show: false,

    }
  },
  mounted () {

  },
  props: {
    num: String,
    title: String,
    color: String,
  },
  methods: {
  }
}
</script>

<style lang='scss' scoped>
@import '@/assets/scss/mixin1700.scss';

.container{
  padding-top: 80px;
  @include hamburger {
    flex-direction: row ;
    justify-content: center;
    height: 70px;
    padding-top: 0px;
    font-size: 18px !important;
  }
  .bar{
    width: 2px;
    height: 15px;
    @include hamburger {
      transform: rotate(-90deg);
    }
  }
  .text-landscape{
    @include hamburger {
      width: auto !important;
      margin: unset;
    }
  }
}

</style>
