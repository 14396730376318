<template>
  <div class="h-full">
    <div class="product h-full w-full" :style="{backgroundImage: 'url(' + item.picture + ')','background-repeat': 'no-repeat','backgroundSize': 'cover','background-position': 'center center'}" >
      <div class="introduce">
        <!-- tag -->
        <div class="tag-row flex items-center">
          <div class="tag text-white mr-10 font-XQ font-medium">PRODUCT</div>
          <iconFont class="pc-show" icon='bamboodragonfly' color='#fff' :size='32' ></iconFont>
          <iconFont class="mb-show" icon='bamboodragonfly' color='#1E456C' :size='28' ></iconFont>
        </div>
        <!-- 内容标题 -->
        <div class="titles mt-20">
          <div v-for="(t,i) in item.name.split('')" :key="i" class="circle-bg inline-block text-center text-32 rounded-full text-white live-XQ wow fadeInUp">{{t}}</div>
        </div>
        <!-- 内容 -->
        <div class="content mt-35 text-white h-258 overflow-y-auto pr-5 wow fadeInUp" >{{item.introduce}}</div>
      </div>
      <div class="imgs h-217 w-full" :style="{backgroundImage: 'url(' + item.picture + ')','background-repeat': 'no-repeat','backgroundSize': 'cover','background-position': 'center center'}" ></div>
    </div>
  </div>
</template>

<script>
import iconFont from '@/components/iconFont'

export default {
  name: 'product',
  data () {
    return {
      show: false,
    }
  },
  props: {
    item: Object,
  },
  mounted () {
    // this.$nextTick(() => {
    //   // 在dom渲染完后,再执行动画
    //   const wow = new WOW({
    //     live: true
    //   })
    //   wow.init()
    // })
    // new WOW().init()
  },
  components: {
    iconFont,
  },
  methods: {
  }
}
</script>

<style lang='scss' scoped>
@import '@/assets/scss/mixin1700.scss';

.product{
  @include hamburger {
    background: unset !important;
  }

}
.introduce{
  width: 409px;
  margin-left: 10%;
  padding-top: 190px;
  @include hamburger {
    width: 100%;
    padding-top: 0px;
    margin-left: 0;
  }
  .tag-row{
    // @include hamburger {
    //   margin-top: 0px !important;
    // }
  }
  .tag{
    @include hamburger {
      color: #1E456C !important;
    }
  }
  .content{
    @include hamburger {
      color: #1E456C !important;
      height: 90px;
      margin: 10px 0;
    }
  }
}
.imgs{
  display: none !important;
  @include hamburger {
    display: block !important;
  }
}

.titles{
  @include hamburger {
    margin-top: 0px !important;
  }
  .circle-bg{
    width: 52px;
    height: 52px;
    background: #CA4938;
    margin-right: -5px;
    @include hamburger {
      width: 28px;
      height: 28px;
      font-size: 16px;
      margin-top: 0px !important;
      background: #1E456C;
    }
  }

}

/*定义滚动条高宽及背景
 高宽分别对应横竖滚动条的尺寸*/
::-webkit-scrollbar
{
    width: 4px;
    height:10px;
    background-color:#F5F5F5;
}
/*定义滚动条轨道
 内阴影+圆角*/
::-webkit-scrollbar-track
{
    -webkit-box-shadow:inset 0 0 2px rgba(30, 69, 108, 0.5);
    border-radius:10px;
    background-color:#F5F5F5;
}
/*定义滑块
 内阴影+圆角*/
::-webkit-scrollbar-thumb
{
    border-radius:10px;
    -webkit-box-shadow:inset 0 0 2px rgba(30, 69, 108, 0.5);
    background-color:#1E456C;
}
</style>
