<template>
  <div class="w row p-0 m-0 bg-white" >
    <steps class="container-left col-md-1 p-0 m-0 pc-show" :num='num' title='加盟流程' color='#CA4938' ></steps>
    <div class="center col-md-11 p-0 m-0 wow fadeIn">
      <div class="container-center h-full">
        <div class="container-center-bg h-full w-full" :class="isIndex?'imgBg':'colorBg'" >
          <div class="container-center-con h-full w-full">
            <img v-if="isIndex" class="pc-show" src="../assets/images/step.png" alt="">
            <img v-else class="pc-show" src="../assets/images/join.png" alt="">
            <div class="mb-show">
              <steps :num='num' title='加盟流程' :color='isIndex?"#fff":"#1E456C"' ></steps>
              <img v-if="isIndex" src="../assets/images/step-mb.png" alt="">
              <img v-else src="../assets/images/join-mb.png" alt="">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'botFoot',
  data () {
    return {
    }
  },
  props: {
    num: String,
    isIndex: Boolean,
  },
  mounted () {
  },
  methods: {
  }
}
</script>

<style lang='scss' scoped>
@import '@/assets/scss/mixin1700.scss';

.center{
  padding: 60px 0 0!important;
  @include hamburger {
    padding: 0px !important;
  }
}
.container-center{
  @include hamburger {
    padding-top: 0 !important;
  }
  .container-center-bg{
    // background: url(../assets/images/bg06.png) no-repeat center;
    .container-center-con{
      box-sizing: border-box;
    }
  }
}
.imgBg{
  background: url(../assets/images/bg06.png) no-repeat center;
}
.colorBg{
  background: #FCFAF0;
}
.container-left{
  @include hamburger {
    height: auto !important;
  }
}

</style>
