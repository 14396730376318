<template>
  <div class="swiper h-full">
    <!-- Swiper -->
    <div class="swiper-container swiper-container-idea h-full">
      <div class="swiper-wrapper">
          <div v-for="(item,index) in list" :key="index" class="swiper-slide flex row p-0 m-0">
            <div class="swiper-slide-left col-md-5 p-0 m-0">
              <!-- 内容标题 -->
              <div class="flex items-center mt-60">
                <headlines class="headlines font-XQ" :title='item.title' :english="item.tag" color="#fff" align='left' ></headlines>
              </div>
              <!-- 内容 -->
              <div class="content mt-35 text-white overflow-y-auto pr-5" v-html='item.content'></div>
            </div>
            <div class="swiper-slide-right col-md-7 h-full  p-0 m-0" :style="{backgroundImage: 'url(' + item.img + ')','background-repeat': 'no-repeat','backgroundSize': 'cover','background-position': 'center center'}"></div>
          </div>
      </div>
      <!-- Add Pagination -->
      <div class="swiper-pagination swiper-pagination-idea"></div>
    </div>
  </div>
</template>

<script>
import Swiper from 'swiper'

export default {
  name: 'idea',
  data () {
    return {
      swiper: null,
      list: [
        {
          tag: 'BRAND CONCEPT',
          title: '秉持初心,提高创新',
          content: `在延续古法的基础上加以创新<br>
                    使用天然健康原材料，确保品牌的品质呈现`,
          img: 'https://s3.moke.cloud/shuangmei/47aa7876-ee77-4512-a263-f4edf23bd60c.png'
        },
        {
          tag: 'BRAND CONCEPT',
          title: '健康优选,甄选用料',
          content: `八道匠人工序，八小时细火慢煮<br>
                    每一步都遵循严格标准`,
          img: 'https://s3.moke.cloud/shuangmei/47aa7876-ee77-4512-a263-f4edf23bd60c.png'
        },

      ]
    }
  },
  mounted () {
    setTimeout(() => {
      this.swiper = new Swiper('.swiper-container-idea', {
        mousewheel: {
          forceToAxis: true,
        },
        // autoplay: {
        //   delay: 50000
        // },
        spaceBetween: 0,
        pagination: {
          el: '.swiper-pagination-idea',
        },
        slidesPerView: 1
      })
      this.show = true
    }, 100)
  },
  components: {
  },
  methods: {
  },
  destroy () {
    if (this.swiper) {
      this.swiper.destroy()
    }
  }
}
</script>

<style lang='scss' scoped>
@import '@/assets/scss/mixin1700.scss';

.swiper{
  ::v-deep{
    .swiper-pagination{
      text-align: left !important;
      padding-left: 80px !important;
      bottom: 100px;
      @include hamburger {
        text-align: center !important;
        bottom: 0px;
        padding-left: 0 !important;
      }
    }
    .swiper-pagination-bullet{
      background: unset;
      border: 1.5px solid #FFFFFF;
      box-sizing: border-box;
      opacity: 1;
      @include hamburger {
        border: 1.5px solid #CA4938;
      }
    }
    .swiper-pagination-bullet-active{
      background: #FFFFFF;
      @include hamburger {
        background: #CA4938;
      }
    }

  }
}
.swiper-container{
  @include hamburger {
    padding-bottom: 20px;
  }
  .items-center{
    @include hamburger {
      margin-top: 20px;
    }
  }
  .swiper-slide{
    height: 608px;
    @include hamburger {
      height: 460px;
    }
  }
  .swiper-slide-left{
    padding-left: 80px !important;
    background: #CA4938 url(../assets/images/texture.png) repeat center center;
    @include hamburger {
      padding: 0 20px !important;
      height: 230px;
    }
  }
  .swiper-slide-right{
    @include hamburger {
      height: 230px;
    }
  }
}

.content{
  @include hamburger {
    // height: 193px;
    margin-top: 20px;

  }
}

</style>
