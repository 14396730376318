<template>
  <div class="swiper h-full">
    <!-- Swiper -->
    <div class="swiper-container swiper-container-product h-full">
      <div class="swiper-wrapper">
        <div v-for="(item,index) in list" :key="index" class="swiper-slide"  >
          <div class="img wow fadeInRight" :style="{backgroundImage: 'url(' + item.picture + ')','background-repeat': 'no-repeat','backgroundSize': 'cover','background-position': 'center center'}" ></div>
          <div class="introduce text-white mt-20 wow fadeInUp">
            <div class="tag h-52 font-XQ text-white relative">
              PRODUCT
              <iconFont class="icon relative bottom-20" icon='wavyline' color='#fff' :size='60' ></iconFont>
            </div>
            <div class="introduce-title live-XQ text-40 text-white">{{item.name}}</div>
            <div class="introduce-introduce mt-30 leading-8 scroll-bar">
              {{item.introduce}}
            </div>
          </div>
        </div>
      </div>
      <div class="swiper-pagination swiper-pagination-product font-XQ text-white"></div>
    </div>
  </div>
</template>

<script>
import Swiper from 'swiper'
import iconFont from '@/components/iconFont'

export default {
  name: 'productSwiper',
  data () {
    return {
      swiper: null,
    }
  },
  props: {
    list: Array,
  },
  mounted () {
    setTimeout(() => {
      this.swiper = new Swiper('.swiper-container-product', {
        mousewheel: {
          forceToAxis: true,
        },
        // autoplay: {
        //   delay: 5000
        // },
        pagination: {
          el: '.swiper-pagination-product',
          type: 'fraction',
        },
        navigation: {
          nextEl: '.swiper-button-next-product',
          prevEl: '.swiper-button-prev-product',
        },
        slidesPerView: 1
      })
    }, 300)
  },
  components: {
    iconFont,
  },
  methods: {
  },
  destroy () {
    if (this.swiper) {
      this.swiper.destroy()
    }
  }
}
</script>

<style lang='scss' scoped>
@import '@/assets/scss/mixin1700.scss';

.swiper{
  ::v-deep{
    .swiper-pagination{
      text-align: right !important;
      padding-right: 70px;
      @include hamburger {
        bottom: 20px;
        padding-right: 0px;
      }
    }
    .swiper-pagination-bullet{
      background: unset;
      border: 1.5px solid #FFFFFF;
      box-sizing: border-box;
      opacity: 1;
    }
    .swiper-pagination-bullet-active{
      background: #FFFFFF;
    }
  }
}
.introduce{
  margin-top: 80px;
  @include hamburger {
    margin-top: 20px;
    margin-left: 0;
  }
  .tag{
    @include hamburger {
      height: 40px;
      font-size: 12px;
    }
  }
  .introduce-title{
    @include hamburger {
      font-size: 18px;
    }
  }
  .introduce-introduce{
    @include hamburger {
      height: 85px;
      margin: 10px 0;
      overflow-y: auto;
    }
  }
}

.swiper-container{
  @include hamburger {
    padding-bottom: 20px;
  }
  .swiper-wrapper{
    .swiper-slide{
      @include hamburger {
        background: unset !important;
      }
      .img{
        width: 100%;
        height: 850px;
        box-sizing: border-box;
        @include hamburger {
          height: 174px;
        }
      }
    }
  }
}

</style>
