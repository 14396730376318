export default {
  data () {
    return {
    }
  },
  computed: {
    // 判断是为苹果手机登录
    isIosStatus () {
      return this.$tkUa.isPlus && this.$tkUa.isIos
    }
  },
  mounted () {
    this.setStatusBar('dark')
  },
  methods: {
    // 动态请求字体包，base64格式返回直接使用
    // 云代码使用fontmin解析传入的text，默认返回ArrayBuffer格式，需在云代码转为base64格式返回
    // 接收返回参数后，在页面追加到head中 class=live-XQ
    async getFont (text) {
      let list = await this.$axios.post('/font', {
        text: text
      })
      const style = document.createElement('style')
      style.innerHTML = `
      @font-face {
        font-family: 'live';
        src: url("${list}") format('truetype');
        font-weight: normal;
        font-style: normal;
      }
      .live-XQ{
        font-family: 'live' !important;
      }
    `
      document.head.appendChild(style)
      // console.log('完成', list)
    },
    // 获取符合条件的单条数据
    async onlyId (className, obj) {
      let res = await this.$axios.get(`/classes/${className}`, {
        params: {
          limit: 1,
          where: obj
        }
      })
      return res.results ? res.results[0] : null
    },
    // 统计相关数量接口调用方法
    async statisticsNum (className, obj) {
      let countData = await this.$axios.get(`/classes/${className}`, {
        params: {
          count: 1,
          limit: 0,
          where: obj
        }
      })
      if (countData.count) { return countData.count } else { return 0 }
    },
    // 统计数据总数量
    async getGroupTotal (className, obj) {
      let res = await this.$axios.get(`/aggregate/${className}`, {
        params: {
          group: {
            objectId: '',
            total: { $sum: 1 }
          },
          where: obj,
          match: obj
        }
      })
      return res.results[0].total
    },
    // 设置状态栏文字颜色
    setStatusBar (color) {
      if (window.plus) { window.plus.navigator.setStatusBarStyle(color) }
    }
  }
}
