<template>
  <div class="swiper h-full">
    <!-- Swiper -->
    <div class="swiper-container swiper-container-swiperShopMb h-full">
      <div class="swiper-wrapper">
        <div v-for="(item,index) in list" :key="index" class="swiper-slide" :style="{backgroundImage: 'url(' + item.picture + ')','background-repeat': 'no-repeat','backgroundSize': 'cover','background-position': 'center center'}" >
          <div class="img" :style="{backgroundImage: 'url(' + item.picture + ')','background-repeat': 'no-repeat','backgroundSize': 'cover','background-position': 'center center'}"></div>
          <div class="left text-white mt-20">
            <div class="h-32 font-XQ text-white relative">
              STORE
              <iconFont class="icon relative bottom-15" icon='wavyline' color='#fff' :size='44' ></iconFont>
            </div>
            <div class="container-two-bottom-left-title live-XQ text-18 text-white">{{item.name}}</div>
            <div class="leading-8">
              {{item.address}}
            </div>
          </div>
        </div>
      </div>
      <!-- Add Pagination -->
      <div class="swiper-pagination swiper-pagination-swiperShopMb"></div>
    </div>
  </div>
</template>

<script>
import Swiper from 'swiper'
import iconFont from '@/components/iconFont'

export default {
  name: 'swiperProductMb',
  data () {
    return {
      swiper: null,
    }
  },
  props: {
    list: Array,
  },
  mounted () {
    setTimeout(() => {
      this.swiper = new Swiper('.swiper-container-swiperShopMb', {
        // loop: true,
        mousewheel: {
          forceToAxis: true,
        },
        // autoplay: {
        //   delay: 5000
        // },
        pagination: {
          el: '.swiper-pagination-swiperShopMb',
        },
        slidesPerView: 1
      })
    }, 300)
  },
  components: {
    iconFont,
  },
  methods: {
  },
  destroy () {
    if (this.swiper) {
      this.swiper.destroy()
    }
  }
}
</script>

<style lang='scss' scoped>
@import '@/assets/scss/mixin1700.scss';

.swiper{
  ::v-deep{
    .swiper-pagination{
      @include hamburger {
        bottom: 0px;
      }
    }
    .swiper-pagination-bullet{
      background: unset;
      border: 1.5px solid #FFFFFF;
      box-sizing: border-box;
      opacity: 1;
    }
    .swiper-pagination-bullet-active{
      background: #FFFFFF;
    }
  }
}
.introduce{
  width: 409px;
  margin-left: 10%;
  margin-top: 150px;
  @include hamburger {
    margin-top: 20px;
    margin-left: 0;
  }
  .tag-row{
    @include hamburger {
      margin-top: 0px !important;
    }
  }
  .tag{
    @include hamburger {
      color: #1E456C !important;
    }
  }
  .content{
    @include hamburger {
      color: #1E456C !important;
      height: auto;
    }
  }
  .icon-pc{
    @include hamburger {
      display: none;
    }
  }
  .icon-mb{
    display: none;
    @include hamburger {
      display: block;
    }
  }
}
.img{
  display: none;
  @include hamburger {
    display: block;
  }
}
.swiper-container{
  @include hamburger {
    padding-bottom: 20px;
  }
  .swiper-wrapper{
    .swiper-slide{
      @include hamburger {
        background: unset !important;
      }
      .img{
        width: 100%;
        height: 222px;
        border: 3px solid #FFFFFF;
        box-sizing: border-box;
      }
    }
  }
}

.title{
  @include hamburger {
    margin-top: 10px !important;
  }
  .circle-bg{
    width: 52px;
    height: 52px;
    background: #CA4938;
    margin-right: -5px;
    @include hamburger {
      width: 28px;
      height: 28px;
      font-size: 16px;
      margin-top: 0px !important;
      background: #1E456C;
    }
  }

}
.content{
  @include hamburger {
    height: 193px;
    margin: 10px 0;
  }
}

</style>
