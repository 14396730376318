let plugins = ['tk', 'index', 'vuexStorage']
// if (process.env.VUE_APP_ENV === 'web') {
plugins = [
  ...plugins,
  'user',
  'routerAuth'
]
// }
const config = {
  vuexStorageKeys: [
    'sessionToken',
    'user',
  ],
  plugins: plugins,
  version: '1.0.0', // app版本号
  appID: 'AoSiRihYBlEmRzHUIZGn2U70ELOoPbCt',
  masterKey: 'sFRE9cLkO5RsMORLBkMgGM6P3Pr12FZS',
  apiUrl: 'https://cloud.shuangmei.mokekeji.com',
  cloudUrl: 'https://cloud.shuangmei.mokekeji.com',
  // apiUrl: 'http://192.168.50.221:3333',
  // cloudUrl: 'http://192.168.50.221:3333',
  restKey: '',
  statusBarColor: 'rgba(255, 255, 255, 0)',
  mobileView: true,
  modelJSON: require('./assets/json/model'),
  iconJSON: require('./assets/json/icon'),
  // moke
  payId: '',
  paysecret: ''
}
export default config
