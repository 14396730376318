<template>
  <div class="swiper h-full">
    <!-- Swiper -->
    <div class="swiper-container swiper-container-swiperDevelopment h-full">
      <div class="swiper-wrapper text-whiteMilk">
          <div v-for="(item,index) in list" :key="index" class="swiper-slide" :style="{backgroundImage: 'url(' + item.picture + ')','background-repeat': 'no-repeat','backgroundSize': 'cover','background-position': 'center center'}">
            <div class="details">
              <!-- 内容标题 -->
              <div class="title text-24">{{item.developmentTime | dateYear}}</div>
              <!-- 内容 -->
              <div class="content mt-12">{{item.synopsis}}</div>
            </div>
          </div>
      </div>
    </div>
    <div class="pc-show swiper-button">
      <!-- Add Arrows -->
      <div class="swiper-button-prev swiper-button-prev-swiperDevelopment">
        <iconFont class="icon" icon='leftarrow' color='#fff' :size='14' ></iconFont>
      </div>
      <div class="swiper-button-next swiper-button-next-swiperDevelopment">
        <iconFont class="icon" icon='rightarrow' color='#fff' :size='14' ></iconFont>
      </div>
    </div>
  </div>
</template>

<script>
import Swiper from 'swiper'
import iconFont from '@/components/iconFont'

export default {
  name: 'swiperDevelopment',
  data () {
    return {
      swiper: null,
    }
  },
  props: {
    list: Array,
  },
  mounted () {
    setTimeout(() => {
      this.swiper = new Swiper('.swiper-container-swiperDevelopment', {
        // loop: true,
        mousewheel: {
          forceToAxis: true,
        },
        // autoplay: {
        //   delay: 5000
        // },
        spaceBetween: 50,
        navigation: {
          nextEl: '.swiper-button-next-swiperDevelopment',
          prevEl: '.swiper-button-prev-swiperDevelopment',
        },
        slidesPerView: 2.7
      })
      this.show = true
    }, 100)
  },
  components: {
    iconFont,
  },
  methods: {
  },
  destroy () {
    if (this.swiper) {
      this.swiper.destroy()
    }
  }
}
</script>

<style lang='scss' scoped>
@import '@/assets/scss/mixin1700.scss';

.swiper{
  ::v-deep{
    .swiper-button-next{
      position: absolute;
      top: unset !important;
      bottom: -50px !important;
      left: 58px;
      @include hamburger {
        display: none;
      }
    }
    .swiper-button-prev{
      position: absolute;
      top: unset !important;
      left: unset !important;
      bottom: -50px;
      left: 0;
      @include hamburger {
        display: none;
      }
    }
  }
}
.swiper-container{
  @include hamburger {
    padding-bottom: 20px;
  }
  .swiper-slide{
    height: 693px;
  }
  // .items-center{
  //   @include hamburger {
  //     margin-top: 20px;
  //   }
  // }
  .details{
    padding: 20px 0 40px;
    box-sizing: border-box;
    position: absolute;
    bottom: 0;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%);
    .title{
      border-left: 4px solid #CA4938;
      padding-left: 30px;
      box-sizing: border-box;
    }
    .content{
      padding: 0 30px;
      box-sizing: border-box;
      margin-top: 12px;
    }
  }
}
.swiper-button{
  width: 100px;
  height: 50px;
  div{
    width: 50px;
    height: 50px;
    background: #CA4938;
    opacity: 1;
    .icon{
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%,-50%);
    }
  }
}

</style>
