<template>
  <div class="swiper h-full">
    <!-- Swiper -->
    <div class="swiper-container swiper-container-swiperProduct h-full">
      <div class="swiper-wrapper h-full">
        <div class="swiper-slide h-full" v-for="(item,index) in list" :key="index">
          <product :item='item' ></product>
        </div>
      </div>
      <!-- Add Pagination -->
      <div class="swiper-pagination swiper-pagination-swiperProduct"></div>
    </div>
  </div>
</template>

<script>
import Swiper from 'swiper'
import product from '@/components/product'

export default {
  name: 'swiperProduct',
  data () {
    return {
      swiper: null,
    }
  },
  props: {
    list: Array,
  },
  mounted () {
    setTimeout(() => {
      this.swiper = new Swiper('.swiper-container-swiperProduct', {
        mousewheel: {
          forceToAxis: true,
        },
        // autoplay: {
        //   delay: 5000
        // },
        spaceBetween: 20,
        pagination: {
          el: '.swiper-pagination-swiperProduct',
        },
        slidesPerView: 1,
      })
    }, 300)
  },
  components: {
    product,
  },
  methods: {
  },
  destroy () {
    if (this.swiper) {
      this.swiper.destroy()
    }
  }
}
</script>

<style lang='scss' scoped>
@import '@/assets/scss/mixin1700.scss';

.swiper{
  ::v-deep{
    .swiper-pagination{
      @include hamburger {
        bottom: 20px;
      }
    }
    .swiper-pagination-bullet{
      background: unset;
      border: 1.5px solid #FFFFFF;
      box-sizing: border-box;
      opacity: 1;
    }
    .swiper-pagination-bullet-active{
      background: #FFFFFF;
    }
  }
}
.introduce{
  width: 409px;
  margin-left: 10%;
  margin-top: 150px;
  @include hamburger {
    width: 100%;
    margin-top: 0px;
    margin-left: 0;
  }
  .tag-row{
    @include hamburger {
      margin-top: 0px !important;
    }
  }
  .tag{
    @include hamburger {
      color: #1E456C !important;
    }
  }
  .content{
    @include hamburger {
      color: #1E456C !important;
      height: 150px;
      margin: 10px 0;
    }
  }
}
.img{
  display: none;
  @include hamburger {
    display: block;
  }
}
.swiper-container{
  @include hamburger {
    padding: 0 20px 20px;
  }
  .swiper-wrapper{
    .swiper-slide{
      @include hamburger {
        background: unset !important;
      }
    }
  }
}

.title{
  @include hamburger {
    margin-top: 10px !important;
  }
  .circle-bg{
    width: 52px;
    height: 52px;
    background: #CA4938;
    margin-right: -5px;
    @include hamburger {
      width: 28px;
      height: 28px;
      font-size: 16px;
      margin-top: 0px !important;
      background: #1E456C;
    }
  }

}

/*定义滚动条高宽及背景
 高宽分别对应横竖滚动条的尺寸*/
::-webkit-scrollbar
{
    width: 4px;
    height:10px;
    background-color:#F5F5F5;
}
/*定义滚动条轨道
 内阴影+圆角*/
::-webkit-scrollbar-track
{
    -webkit-box-shadow:inset 0 0 2px rgba(30, 69, 108, 0.5);
    border-radius:10px;
    background-color:#F5F5F5;
}
/*定义滑块
 内阴影+圆角*/
::-webkit-scrollbar-thumb
{
    border-radius:10px;
    -webkit-box-shadow:inset 0 0 2px rgba(30, 69, 108, 0.5);
    background-color:#1E456C;
}
</style>
