<template>
  <div class="swiper h-full">
    <!-- Swiper -->

      <!-- swiper Thumbs -->
      <div class=" pl-40">
        <swiper :options="swiperOptionThumbs" class="gallery-thumbs" ref="swiperThumbs">
          <swiper-slide class="swiper-slide" v-for="(item,index) in list" :key="index">
            <div class="img" :style="{backgroundImage: 'url(' + item.picture + ')','background-repeat': 'no-repeat','backgroundSize': 'cover','background-position': 'center center'}"></div>
          </swiper-slide>
        </swiper>
      </div>
      <!-- swiper top -->
      <swiper :options="swiperOptionTop" class="swiper-container gallery-top" ref="swiperTop">
        <swiper-slide class="slide-1" v-for="(item,index) in list" :key="index">
          <div class="flex justify-between">
            <div class="left text-white">
              <div class="h-40 font-XQ text-white relative">
                STORE
                <iconFont class="icon relative bottom-20" icon='wavyline' color='#fff' :size='60' ></iconFont>
              </div>
              <div class="container-two-bottom-left-title live-XQ text-40 text-white">{{item.name}}</div>
              <div class="mt-30 leading-8 ell">
                {{item.address}}
              </div>
            </div>
            <div class="p-0 my-0 flex flex-col items-center cursor-pointer mr-100 an-right" @click="$push('/shop')">
              <div class="icon-more-big icon-more">
                <iconFont class="icon" icon='thinarrow' color='#fff' :size='50' ></iconFont>
              </div>
              <div class="text-white mt-10">查看更多</div>
            </div>
          </div>
        </swiper-slide>
      </swiper>
      <div class="swiper-pagination swiper-pagination-swiperShop"></div>
  </div>
</template>

<script>
// import Swiper from 'swiper'
import iconFont from '@/components/iconFont'

export default {
  name: 'swiperShop',
  data () {
    return {
      swiperOptionTop: {
        spaceBetween: 28,
        // effect: 'fade',
        loop: true,
        loopedSlides: 4,
      },
      swiperOptionThumbs: {
        pagination: {
          el: '.swiper-pagination-swiperShop',
        },
        spaceBetween: 28,
        loop: true,
        slidesPerView: 4,
        slideToClickedSlide: true,
        watchSlidesVisibility: true
      },

    }
  },
  props: {
    list: Array,
  },
  mounted () {
    this.$nextTick(() => {
      const swiperTop = this.$refs.swiperTop.swiper
      const swiperThumbs = this.$refs.swiperThumbs.swiper
      swiperTop.controller.control = swiperThumbs
      swiperThumbs.controller.control = swiperTop
    })
  },
  components: {
    iconFont,
  },
  methods: {
  },
  destroy () {
    if (this.galleryThumbs) {
      this.galleryThumbs.destroy()
    }
    if (this.galleryTop) {
      this.galleryTop.destroy()
    }
  }
}
</script>

<style lang='scss' scoped>

.swiper-container {
  width: 100%;
  height: 300px;
  margin-left: auto;
  margin-right: auto;
}
.swiper-slide {
  background-size: cover;
  background-position: center;
}
.gallery-top {
  // height: 380px;
  width: 100%;
  padding: 80px 0;
}
.gallery-thumbs {
  height: 320px;
  box-sizing: border-box;
  // padding: 10px 0;
  .swiper-slide {
    box-sizing: border-box;
    .img{
      width: 100%;
      height: 222px;
      border: 2px solid #FFFFFF;
      box-sizing: border-box;
      position: absolute;
      bottom: 2px;
      left: 0;
      transition: 0.3s all 0.3s;
    }
  }
  .swiper-slide-active{
    .img{
      height: 320px;
    }
  }
}

@import '@/assets/scss/mixin1700.scss';

.swiper{
  ::v-deep{
    .swiper-pagination{
      position: absolute;
      right: 100px;
      @include hamburger {
        bottom: 0px;
      }
    }
    .swiper-pagination-bullet{
      background: unset;
      border: 1.5px solid #FFFFFF;
      box-sizing: border-box;
      opacity: 1;
      margin: 0 4px;
    }
    .swiper-pagination-bullet-active{
      background: #FFFFFF;
    }

  }
}
.swiper-container{
  @include hamburger {
    padding-bottom: 50px;
  }
}
.swiper-button{
  width: 100px;
  height: 50px;
  div{
    width: 50px;
    height: 50px;
    background: #CA4938;
    opacity: 1;
    .icon{
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%,-50%);
    }
  }
}

.title{
  .circle-bg{
    width: 52px;
    height: 52px;
    /* 1 */
    background: #1E456C;
    margin-right: -5px;
    @include hamburger {
      width: 28px;
      height: 28px;
      font-size: 16px;
      margin-top: 5px;
    }
  }
  .circle-bg:nth-child(1){
    // margin-left: 0;
  }
}
.content{
  @include hamburger {
    height: 193px;
    margin-top: 20px;
  }
}

</style>
