<template>
  <div class="swiper h-full">
    <!-- Swiper -->
    <div class="swiper-container swiper-container-swiperAbout h-full">
      <div class="swiper-wrapper">
          <div v-for="(item,index) in list" :key="index" class="swiper-slide">
            <!-- tag -->
            <div class="flex items-center mt-60">
              <div class="text-blue mr-10 font-XQ font-medium">{{item.tag}}</div>
              <iconFont class="icon" icon='bamboodragonfly' color='#1E456C' :size='32' ></iconFont>
            </div>
            <!-- 内容标题 -->
            <div class="titles mt-20">
              <div v-for="(t,i) in item.titleArr" :key="i" class="circle-bg inline-block text-center text-32 rounded-full text-white font-XQ wow fadeInRight">{{t}}</div>
            </div>
            <!-- 内容 -->
            <div class="content mt-35 text-blue h-258 overflow-y-auto pr-5 wow fadeInUp" v-html='item.content'></div>
          </div>
      </div>
      <!-- Add Pagination -->
      <div class="swiper-pagination swiper-pagination-swiperAbout"></div>

    </div>
    <div class="pc-show swiper-button">
      <!-- Add Arrows -->
      <div class="swiper-button-prev swiper-button-prev-swiperAbout an-left">
        <iconFont class="icon" icon='leftarrow' color='#fff' :size='14' ></iconFont>
      </div>
      <div class="swiper-button-next swiper-button-next-swiperAbout an-right">
        <iconFont class="icon" icon='rightarrow' color='#fff' :size='14' ></iconFont>
      </div>
    </div>
  </div>
</template>

<script>
import Swiper from 'swiper'
import iconFont from '@/components/iconFont'

export default {
  name: 'swiperAbout',
  data () {
    return {
      swiper: null,
      list: [
        {
          tag: 'ABOUT',
          title: '双美豆沙牛乳',
          content: `好事成双、纯美相伴，以双美之念映四时之季，琢朴，纯然。<br>
                    春生夏长，秋收冬藏，取予有节，出入有时，开阖张歙，不失其叙，喜怒刚柔，不离其理。<br>
                    美者，是念想、是期盼，时者，是量度、是见证，恰量最适合的维度成就美，赏见最踏实的手艺塑造美；<br>
                    双美熠熠，四时相候，以双美之物为媒，永结悠然岁月之景。`,
          titleArr: []
        },
        {
          tag: 'BRAND STORY',
          title: '记忆里妈妈的味道',
          content: `台湾的秋，不下雨的时候总觉得闷热，母亲常在这个时节为孩子们准备营养败火的豆沙甜食。<br>
                    这天一早阿柱母亲如往常一样在早市择好了新鲜的高山绿毛豆，硕大的绿豆在母亲的多道工序后，慢
                    慢变成了绵软的豆沙。为了呈现最佳口感，妈妈将处理好的豆沙静置一旁，出门去了... ...<br>
                    妈妈刚走，阿柱家的小妹便溜了进来， 正当四处寻觅时， 一不小心竟把母亲准备的豆沙打翻在一旁的
                    牛奶中。小妹害怕妈姆的责怪急急来寻哥哥阿柱，阿柱望著窗外穿过云朵的鸿雁，灵机一动，将混了豆
                    沙的牛乳放入冰箱冷藏。
                    <br>
                    外出归来的母亲，无意尝到这特殊的饮料，竟赞不绝口。`,
          titleArr: []
        },
        {
          tag: 'ABOUT US',
          title: '秉持初心提高创新',
          content: `在延续古法的基础上加以创新<br>
                    使用天然健康原材料，确保品牌的品质呈现`,
          titleArr: []
        },
        {
          tag: 'ABOUT US',
          title: '双美一路走来',
          content: `自2020年成立，发展至今历时2年，共创建200+店 ，入驻53座城市，我们依然继续努力前行，秉持初心，保持自我。`,
          titleArr: []
        }
      ]
    }
  },
  mounted () {
    this.list.forEach((t) => {
      t.titleArr = t.title.split('')
    })
    setTimeout(() => {
      this.swiper = new Swiper('.swiper-container-swiperAbout', {
        mousewheel: {
          forceToAxis: true,
        },
        // autoplay: {
        //   delay: 5000
        // },
        spaceBetween: 20,
        pagination: {
          el: '.swiper-pagination-swiperAbout',
        },
        navigation: {
          nextEl: '.swiper-button-next-swiperAbout',
          prevEl: '.swiper-button-prev-swiperAbout',
        },
        slidesPerView: 1
      })
      this.show = true
    }, 100)
  },
  components: {
    iconFont,
  },
  methods: {
  },
  destroy () {
    if (this.swiper) {
      this.swiper.destroy()
    }
  }
}
</script>

<style lang='scss' scoped>
@import '@/assets/scss/mixin1700.scss';

.swiper{
  ::v-deep{
    .swiper-pagination{
      text-align: left !important;
      @include hamburger {
        text-align: center !important;
        bottom: 0px;
      }
    }
    .swiper-pagination-bullet{
      background: #CECECE;
      opacity: 1;
    }
    .swiper-pagination-bullet-active{
      background: #CA4938;
    }
    .swiper-button-next{
      position: absolute;
      top: unset !important;
      bottom: 0 !important;
      right: 15px;
      @include hamburger {
        display: none;
      }
    }
    .swiper-button-prev{
      position: absolute;
      top: unset !important;
      left: unset !important;
      bottom: 0px;
      right: 68px;
      @include hamburger {
        display: none;
      }
    }
  }
}
.swiper-container{
  @include hamburger {
    padding-bottom: 20px;
  }
  .items-center{
    @include hamburger {
      margin-top: 20px;
    }
  }
}
.swiper-button{
  width: 100px;
  height: 50px;
  div{
    width: 50px;
    height: 50px;
    background: #CA4938;
    opacity: 1;
    .icon{
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%,-50%);
    }
  }
}

.titles{
  @include hamburger {
    margin-top: 0px;
  }
  .circle-bg{
    width: 52px;
    height: 52px;
    /* 1 */
    background: #1E456C;
    margin-right: -5px;
    @include hamburger {
      width: 28px;
      height: 28px;
      font-size: 16px;
      margin-top: 5px;
    }
  }
  .circle-bg:nth-child(1){
    // margin-left: 0;
  }
}
.content{
  @include hamburger {
    height: 193px;
    margin-top: 20px;
  }
}

/*定义滚动条高宽及背景
 高宽分别对应横竖滚动条的尺寸*/
::-webkit-scrollbar
{
    width: 4px;
    height:10px;
    background-color:#F5F5F5;
}
/*定义滚动条轨道
 内阴影+圆角*/
::-webkit-scrollbar-track
{
    -webkit-box-shadow:inset 0 0 2px rgba(30, 69, 108, 0.5);
    border-radius:10px;
    background-color:#F5F5F5;
}
/*定义滑块
 内阴影+圆角*/
::-webkit-scrollbar-thumb
{
    border-radius:10px;
    -webkit-box-shadow:inset 0 0 2px rgba(30, 69, 108, 0.5);
    background-color:#1E456C;
}
</style>
