<template>
    <tk-icon :size='size' :style="{'fill': color, }">{{icon}}</tk-icon>
</template>

<script>
export default {
  name: 'iconFont',
  data () {
    return {
    }
  },

  mounted () {
  },
  props: {
    icon: String,
    color: String,
    size: Number,
  },
  methods: {
  }
}
</script>

<style lang='scss' scoped>

</style>
