<template>
  <div class="meng" :class="FadeAnimate ? 'FriendUrlCreateWindowFadeIn' : 'FriendUrlCreateWindowFadeOut'" v-show="Wrapper" @click="close">
    <div class="pop bg-white " @click.stop="show" >
      <a-icon class="close-icon pc-show" type="close" @click.stop="close" :style="{ fontSize: '22px', color: '#1E456C' }" />
      <div class=" logo">
        <img class="h-full w-full" src="../assets/images/shuangmei.png" alt="">
      </div>
      <div class="pop-con h-full">
        <div class="w-full flex flex-col justify-center">
          <div class="pop-title font-XQ text-blue text-center">严正声明</div>
          <div class="mb-show text-12 text-blue font-XQ text-center">STATEMENT</div>
        </div>
        <div class="con-html overflow-y-auto scroll-bar text-blue" v-html="html" ></div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'steps',
  data () {
    return {

    }
  },
  components: {
  },
  mounted () {

  },
  props: {
    html: String,
    Wrapper: Boolean,
    FadeAnimate: Boolean,
  },
  methods: {
    show () {},
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style lang='scss' scoped>
@import '@/assets/scss/mixin1700.scss';

@mixin pops {
  @media screen and (max-width: 1060px) {
    @content;
  }
}
.pop{
  width: 1060px;
  @include pops {
    width: 95%;
  }
  // height: 730px;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 10px;
  padding: 30px;
  @include hamburger {
    padding: 12px;
  }
  box-sizing: border-box;
  .close-icon{
    float: right;
    cursor: pointer;
  }
  .logo{
    position: absolute;
    width: 150px;
    height: 150px;
    left: 50%;
    top: -60px;
    transform: translateX(-50%);
    background: #FFFFFF;
    border-radius: 50%;
    text-align: center;
    @include hamburger {
      width: 100px;
      height: 100px;
      top: -43px;
    }
    img{
      width: 110px;
      height: 110px;
      border-radius: 50%;
      margin-top: 20px;
      @include hamburger {
        width: 73px;
        height: 73px;
        margin-top: 13px;
      }
    }
  }
  .pop-con{
    @include hamburger {
      margin-top: 40px;
    }
    .pop-title{
      margin: 30px 0 25px;
      font-size: 40px;
      @include hamburger {
        margin: 0px 0 0;
        font-size: 24px;
      }
    }
    .con-html{
      height: 50vh;
      margin-top: 15px;
      @include hamburger {
        height: 50vh;
      }
    }
  }
}
.meng{
  width: 100%;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 9999;
  overflow-y: hidden;
}
.FriendUrlCreateWindowFadeIn{
    animation: FadeIn 0.3s linear;
}
.FriendUrlCreateWindowFadeOut{
    animation: FadeOut 0.3s linear;
}

</style>
