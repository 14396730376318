<template>
  <div class="foot">
    <div class="botFoot w text-white">
      <div class="content row p-0 m-0">
        <div class="col-md-5 foot-left">
          <img src="../assets/images/white-logo.png"
               alt="双美豆沙牛乳">
        </div>
        <div class="col-md-1"></div>
        <div class="col-md-6 foot-right">
          <div class="foot-m foot-font">联系电话：{{phones}}</div>
          <div class="foot-m foot-font">邮箱：{{email}}</div>
          <div class="foot-m foot-font">地址：{{address}}</div>
          <div class="flex gap-30">
            <div v-for="(item,index) in qrCode"
                 :key="index"
                 class="text-center">
              <img class="code-img"
                   :src="item.img"
                   :alt="item.name">
              <div>{{item.name}}</div>
            </div>
          </div>

        </div>
      </div>
      <div class="pb-20">
        <p class="text-center foot-font">温馨提示：创业有风险，投资需谨慎</p>
        <p class="text-center foot-font">河北纳齐餐饮管理有限公司&nbsp;&nbsp;&nbsp;&nbsp;<a class="text-white"
             href="https://beian.miit.gov.cn/#/Integrated/index"
             target="_blank">备案号：冀ICP备2021017169号-1</a></p>
      </div>
    </div>

    <div class="sidebar">
      <div class="item"
           @click="changeLeave">
        <iconFont class="icon pc-show"
                  icon='message'
                  color='#fff'
                  :size='20'></iconFont>
        <iconFont class="icon mb-show"
                  icon='message'
                  color='#fff'
                  :size='16'></iconFont>
        <p class="vertical">在线留言</p>
      </div>
      <div class="item phone-box">
        <iconFont class="icon pc-show"
                  icon='connect'
                  color='#fff'
                  :size='20'></iconFont>
        <iconFont class="icon mb-show"
                  icon='connect'
                  color='#fff'
                  :size='16'></iconFont>
        <p class="vertical">联系电话</p>
        <div class="phone">
          <p class="text-16 text-blue">联系电话</p>
          <a class="text-16 text-red"
             href="tel:400-0077-085">400-0077-085</a>
        </div>
      </div>
    </div>
    <div class="online-message"
         v-if="showLeave">
      <div class="head text-center text-16 text-white">
        在线留言
        <a-icon class="close-icon"
                type="close"
                @click.stop="close"
                :style="{ fontSize: '17px', color: '#fff' }" />
      </div>
      <div class="cont">
        <div class="">
          <input type="text"
                 class="form-control form-name"
                 v-model="name"
                 placeholder="姓名">
        </div>
        <div class="mt-18">
          <input type="phone"
                 class="form-control form-phone"
                 v-model="phone"
                 placeholder="电话">
        </div>
        <div class="mt-18">
          <textarea class="form-control form-contents"
                    style="height: 160px;resize:none;"
                    v-model="contents"
                    placeholder="留言内容"></textarea>
        </div>
        <div class="sub-btn mt-18"
             v-if="canSend"
             style="background: #1E456C"
             @click="submit">
          提交
        </div>
        <div class="sub-btn mt-18"
             v-else
             @click="hint">
          提交
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import iconFont from '@/components/iconFont'

export default {
  name: 'botFoot',
  data () {
    return {
      phones: '', // 手机
      email: '',
      address: '',
      qrCode: [], // 二维码
      name: '',
      phone: '',
      contents: '',
      showLeave: false, // 是否显示留言
      canSend: false, // 是否可以发送
    }
  },
  components: {
    iconFont,
  },
  watch: {
    phone (val) {
      // 验证手机号
      if (val.length === 11 && /^1[2-9]\d{9}$/.test(val)) {
        this.canSend = true
      } else {
        this.canSend = false
      }
    }
  },
  mounted () {
    this.getSetting()
  },
  methods: {
    async close () {
      this.showLeave = false
      this.setEmpty()
    },
    async changeLeave () {
      this.showLeave = !this.showLeave
    },
    async setEmpty () {
      this.name = ''
      this.phone = ''
      this.contents = ''
    },
    hint () {
      this.$message.info({
        content: '请输入正确的手机号码',
        icon: ' ',
      })
    },
    async submit () {
      // 提交姓名、电话、留言内容
      if (!this.phone) {
        this.$message.info({
          content: '请输入电话',
          icon: ' ',
        })
        return
      }
      // 手机号码验证
      if (!(/^1[23456789]\d{9}$/.test(this.phone))) {
        this.$message.info({
          content: '请输入正确的手机号码',
          icon: ' ',
        })
        return
      }

      this.canSend = false
      let post = await this.$axios.post('/sendSmsText/sendSmsText', {
        phone: this.phone,
        name: this.name,
        contents: this.contents
      })
      this.close()
      if (post === 'success') {
        this.$message.info({
          content: '提交成功',
          icon: ' ',
        })
      }
    },
    async getSetting () {
      let settings = await this.$axios.get('/settings')
      this.phones = settings.phone
      this.email = settings.email
      this.address = settings.address
      this.qrCode = settings.qrCode
    }
  }
}
</script>

<style lang='scss' scoped>
@import '@/assets/scss/mixin1200.scss';

.foot-font {
  font-size: 20px;
  @include hamburger {
    font-size: 14px;
  }
}
.foot-m {
  padding-bottom: 20px;
}

.botFoot {
  background: #ca4938 url(../assets/images/texture.png) repeat center center;
  .content {
    padding: 100px 0 80px 0 !important;
    @include hamburger {
      padding: 20px 0 !important;
    }
    .foot-left {
      text-align: center;
      img {
        width: 240px;
        height: 275px;
        float: right;
        @include hamburger {
          width: 120px;
          height: 137px;
          float: unset;
          margin: 0 auto;
        }
      }
    }
    .foot-right {
      @include hamburger {
        margin-top: 20px;
      }
    }
  }
  .code-img {
    width: 100px;
    height: 100px;
    font-size: 16px;
    @include hamburger {
      width: 80px;
      height: 80px;
      font-size: 14px;
    }
  }
}

.sidebar {
  position: fixed;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  z-index: 20;
  .item {
    width: 40px;
    padding: 13px 0;
    background: #1e456c;
    border-radius: 6px 0px 0px 6px;
    text-align: center;
    margin: 6px 0;
    cursor: pointer;
    position: relative;
    &:hover {
      background: #fcfaf0;
      ::v-deep {
        .icon {
          fill: #ca4938 !important;
        }
      }
      .vertical {
        color: #ca4938;
      }
    }
    .icon {
      margin: 0 auto;
      margin-bottom: 6px;
    }
    .vertical {
      writing-mode: vertical-rl;
      margin: 0 auto;
      font-size: 16px;
      color: #fff;
      letter-spacing: 3px;
      @include hamburger {
        font-size: 12px;
      }
    }
    .phone {
      position: absolute;
      width: 165px;
      height: 70px;
      background: #fcfaf0;
      border-radius: 6px;
      left: -180px;
      top: 50%;
      transform: translateY(-50%);
      text-align: left;
      padding: 12px 16px;
      display: none;
      &::before {
        content: "";
        width: 0;
        height: 0;
        border: 8px solid transparent;
        border-left: 8px solid #fcfaf0;
        /* 3 */
        // transform: rotate(90deg);
        position: absolute;
        right: -15px;
        top: 50%;
        transform: translateY(-50%);
      }
      p {
        margin-bottom: 2px;
      }
    }
  }
  .phone-box {
    &:hover .phone {
      display: block;
    }
  }
}
.online-message {
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 21;
  width: 360px;
  border-radius: 6px 0px 0px 0px;
  @include hamburger {
    width: 100%;
    height: 100vh;
    z-index: 1001;
  }
  .head {
    background: #1e456c;
    position: relative;
    height: 52px;
    line-height: 52px;
    .close-icon {
      position: absolute;
      top: 50%;
      right: 17px;
      transform: translateY(-50%);
    }
  }
  .cont {
    height: 100%;
    padding: 24px 24px 30px;
    box-sizing: border-box;
    background-color: #f2f4f7;
  }
  .sub-btn {
    width: 100%;
    height: 48px;
    line-height: 48px;
    background: rgba(30, 69, 108, 0.2);
    border-radius: 6px;
    text-align: center;
    font-size: 16px;
    color: white;
    cursor: pointer;
    margin-top: 30px;
  }
}
</style>
