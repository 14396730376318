<template>
  <div class="swiper h-full">
    <!-- Swiper -->
    <div class="swiper-container swiper-container-swiperDevelopmentMb h-full">
      <div class="swiper-wrapper text-whiteMilk">
          <div v-for="(item,index) in list" :key="index" class="swiper-slide" :style="{backgroundImage: 'url(' + item.picture + ')','background-repeat': 'no-repeat','backgroundSize': 'cover','background-position': 'center center'}">
            <div class="details">
              <!-- 内容标题 -->
              <div class="title text-24">{{item.developmentTime | dateYear}}</div>
              <!-- 内容 -->
              <div class="content mt-12">{{item.synopsis}}</div>
            </div>
          </div>
      </div>
      <!-- Add Pagination -->
      <div class="swiper-pagination swiper-pagination-swiperDevelopmentMb"></div>
    </div>
  </div>
</template>

<script>
import Swiper from 'swiper'
// import iconFont from '@/components/iconFont'

export default {
  name: 'swiperDevelopmentMb',
  data () {
    return {
      show: false,
      swiper: null,
    }
  },
  props: {
    list: Array,
  },
  mounted () {
    setTimeout(() => {
      this.swiper = new Swiper('.swiper-container-swiperDevelopmentMb', {
        // loop: true,
        mousewheel: {
          forceToAxis: true,
        },
        autoplay: {
          delay: 5000
        },
        spaceBetween: 50,
        pagination: {
          el: '.swiper-pagination-swiperDevelopmentMb',
        },
        slidesPerView: 1
      })
      this.show = true
    }, 100)
  },
  components: {
    // iconFont,
  },
  methods: {
  },
  destroy () {
    if (this.swiper) {
      this.swiper.destroy()
    }
  }
}
</script>

<style lang='scss' scoped>
@import '@/assets/scss/mixin1700.scss';

.swiper{
  ::v-deep{
    .swiper-pagination{
      @include hamburger {
        bottom: 20px;
      }
    }
    .swiper-pagination-bullet{
      background: unset;
      border: 1.5px solid #CA4938;
      box-sizing: border-box;
      opacity: 1;
    }
    .swiper-pagination-bullet-active{
      background: #CA4938;
    }
  }
}
.swiper-container{
  @include hamburger {
    padding-bottom: 50px;
  }
  .swiper-slide{
    // height: 480px;
  }
  // .items-center{
  //   @include hamburger {
  //     margin-top: 20px;
  //   }
  // }
  .details{
    padding: 20px 0 40px;
    box-sizing: border-box;
    position: absolute;
    bottom: 0;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%);
    .title{
      border-left: 4px solid #CA4938;
      padding-left: 30px;
      box-sizing: border-box;
    }
    .content{
      padding: 0 30px;
      box-sizing: border-box;
      margin-top: 12px;
    }
  }
}

</style>
