<template>
  <div>
    <div class="header-box w-full" :style="{background: navBg ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0)' }">
      <header class="header">
        <a class="pc-show header__logo" href="#">
          <img v-if="!navBg" class="img-fluid logo-l" src="../assets/images/logo-l.png" alt="logo">
          <img v-else class="img-fluid logo-d" src="../assets/images/logo-d.png" alt="logo">
        </a>
        <a class="mb-show header__logo" href="#">
          <img v-if="!navBg" class="w-70 h-30" src="../assets/images/logo-l.png" alt="logo">
          <img v-else class="w-70 h-30" src="../assets/images/logo-mb-d.png" alt="logo">
        </a>
        <div class="ham-menu" @click="changeVisibility()" :class="{ 'active': burgerVisibility }">
          <span class="ham-menu__item" :style="{'background-color': navBg ? '#CA4938' : '#fff' }"></span>
          <span class="ham-menu__item" :style="{'background-color': navBg ? '#CA4938' : '#fff' }"></span>
          <span class="ham-menu__item" :style="{'background-color': navBg ? '#CA4938' : '#fff' }"></span>
        </div>
        <nav class="header__nav-wrap" :class="{ 'open': burgerVisibility }">
          <ul class="header__nav-list" @click="burgerVisibility = false">
            <li class="header__nav-item " v-for="(item,index) in nav" :key="index" :class="{'active-nav': active === index}" >
              <iconFont class="before" icon='bamboodragonfly' color='#CA4938' :size='35' ></iconFont>
              <span @click="jump(index)" class="header__nav-link" :style="{color: navBg ? '#476685' : '#fff' }" >{{item.name}}</span>
              <!-- <img class="after" src="../assets/images//wave.png"> -->
              <iconFont class="after" icon='wavyline' color='#1E456C' :size='55' ></iconFont>
            </li>
          </ul>
        </nav>
      </header>
    </div>
  </div>
</template>

<script>
// import store from '../store/index'
import iconFont from '@/components/iconFont'
export default {
  data () {
    return {
      nav: [
        { name: '双美首页' },
        { name: '关于双美' },
        { name: '产品在线' },
        { name: '新闻资讯' },
        { name: '门店分布' },
        { name: '招商加盟' },
      ],
      burgerVisibility: false,
      navBg: false,
    }
  },
  props: {
    active: Number,
  },
  // watch: {
  //   $route (to, from) {
  //     // console.log(to.path)
  //     // console.log(this.$store.state.active)
  //     // var scrollTop = window.pageYOffset || window.scrollTop || 0

  //     // if (scrollTop !== 0) {
  //     //   window.scroll(0, 0) // 初始化
  //     // }
  //     switch (to.path) {
  //       case '/':
  //         store.commit('setActive', 0)
  //         break
  //       case '/about':
  //         store.commit('setActive', 1)
  //         break
  //       case '/product':
  //         store.commit('setActive', 2)
  //         break
  //       case '/news':
  //         store.commit('setActive', 3)
  //         break
  //       case '/shop':
  //         store.commit('setActive', 4)
  //         break
  //       case '/join':
  //         store.commit('setActive', 5)
  //         break
  //       default:
  //     }
  //   }
  // },

  components: {
    iconFont,
  },
  mounted () {
    window.addEventListener('scroll', this.scrollChange, true)
  },
  methods: {
    // 保存滚动值，这是兼容的写法
    scrollChange (e) {
      var scrollTop = window.pageYOffset || window.scrollTop || 0
      if (scrollTop > 200 && !this.navBg) {
        this.navBg = true
      }
      if (scrollTop < 200 && this.navBg) {
        this.navBg = false
      }
    },
    changeVisibility () {
      this.burgerVisibility = !this.burgerVisibility
    },

    // 导航跳转页面
    jump (data) {
      // this.active = data
      // store.commit('setActive', data)
      let url = '/'
      switch (data) {
        case 1:
          url = '/about'
          break
        case 2:
          url = '/product'
          break
        case 3:
          url = '/news'
          break
        case 4:
          url = '/shop'
          break
        case 5:
          url = '/join'
          break
        default:
          url = '/'
      }
      this.$push(url)
    },
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/mixin1200.scss';

.header-box{
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
  box-sizing: border-box;
  margin: 0 auto;
  transition: .3s all;
}
.header {
  display: flex;
  align-items: center;
  margin: 0 auto;
  padding: 0 15px;
  @include sp {
    height: 50px;
    width: 100%;
  }
  @include hamburger {
    height: 50px;
    width: 100%;
  }
  @include pc {
    height: 110px;
    width: 100%;
  }
  @include wide-screen{
    height: 110px;
    width: 1200px;
  }
  &__logo {
    display: inline-block;
    height: auto;
    text-decoration: none;
    color: #000;
    @include hamburger {
      height: 31px;
      width: 70px;
    }
    @include pc {
      width: 180px;
      height: 78px;
    }
    @include wide-screen {
      width: 180px;
      height: 78px;
    }
  }
  &__nav-wrap {
    height: 100%;
    width: max-content;
    margin-left: auto;
    @include hamburger {
      position: fixed;
      left: 0;
      top: 0;
      bottom: 0;
      display: none;
      z-index: 10;
      background-color: #FCFAF0;
      width: 100%;
      height: 100vh;
    }
    @include pc {
      display: inline-block;
      margin-right: 16px;
    }
    &.open {
      display: block;
    }
  }
  &__nav-list {
    list-style: none;
    height: 100%;
    margin: 0;
    padding: 0;

    @include hamburger {
      height: 100%;
      position: relative;
      overflow-x: hidden;
      overflow-y: auto;
      padding-top: 30vh;
    }
    @include pc {
      display: flex;
      align-items: center;
    }
    @include wide-screen {
      display: flex;
      align-items: center;
    }
  }
  &__nav-item {
    width: max-content;
    position: relative;
    @include hamburger {
      display: block;
      margin: 0 auto;
      margin-bottom: 60px;
      text-align: center;
    }
    @include pc {
      margin-right: 24px;
    }
    @include wide-screen {
      margin-right: 40px;
    }
  }
  &__nav-link {
    position: relative;
    display: inline-block;
    text-decoration: none;
    color:#fff;
    cursor: pointer;
    @include hamburger {
      font-size: 16px;
      color:#1E456C !important;
    }
    @include pc {
      font-size: 16px;
    }
    @include wide-screen {
      font-size: 16px;
    }
  }
  .header__nav-item:hover > .before, .header__nav-item:hover > .after{
    opacity: 1;
    transition: 0.25s all;
  }
  .header__nav-item:hover{
    .header__nav-link{
    transition: 0.25s all;

      color: #1E456C !important;
    }
  }
  .before{
    position: absolute;
    right: 0px;
    top: -30px;
    opacity: 0;
  }
  .after{
    width: 51px;
    height: 6px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 4px;
    z-index: -1;
    opacity: 0;
  }
}

.showBgClass{
  .header__nav-link{
    color:#476685;
  }
}

.ham-menu {
  @include hamburger {
    display: inline-block;
    box-sizing: border-box;
    margin-left: auto;
    margin-right: 16px;
    transition: all 0.3s;
    z-index: 20;
    position: relative;
    width: 16px;
    height: 14px;
    display: flex;
    justify-content: center;
    border-radius: 5px;
    cursor: pointer;
  }
  @include pc {
    display: none;
  }
  &__item {
    @include hamburger {
      display: inline-block;
      box-sizing: border-box;
      transition: all 0.3s;
      position: absolute;
      width: 16px;
      height: 2px;
      background-color: #fff;
      border-radius: 5px;
    }
    &:nth-of-type(1) {
      top: 0px;
    }
    &:nth-of-type(2) {
      top: 6px;
    }
    &:nth-of-type(3) {
      bottom: 0px;
    }
  }
  &.active {
    border: none;
  }
  &.active .ham-menu__item:nth-of-type(1) {
    -moz-transform: translateY(6px) rotate(-45deg);
    -webkit-transform: translateY(6px) rotate(-45deg);
    transform: translateY(6px) rotate(-45deg);
  }
  &.active .ham-menu__item:nth-of-type(2) {
    transition-duration: 0s;
    opacity: 0;
  }
  &.active .ham-menu__item:nth-of-type(3) {
    -moz-transform: translateY(-6px) rotate(45deg);
    -webkit-transform: translateY(-6px) rotate(45deg);
    transform: translateY(-6px) rotate(45deg);
  }
}
.active-nav{
  .before,.after{
    opacity: 1 !important;
  }
  .header__nav-link{
    color: #1E456C !important;
  }
}
</style>
