<template>
  <div class="container-eight w bg-white text-white">
    <div class="row p-0 m-0 h-full">
      <steps class="pc-show col-md-1" :num='num' title='在线留言' color='#fff' ></steps>
      <div class="pc-show container-center h-full col-md-4 p-0 m-0 font-XQ wow fadeInLeft">
        <img class="shuangmei" src="../assets/images/shuangmei.png" alt="">
        <div class="text-50 text-50 pt-40">執著本真匠制,傳承古早臺味.</div>
        <div class="english-landscape">SHUANG MEI</div>
      </div>
      <div class="container-form h-full col-md-7 row p-0 m-0 items-center items-center justify-center">
        <div class="text-white col-md-8 text-center wow fadeInUp">
          <div class="form-title text-40 font-XQ">在线留言</div>
          <div class="form-subhead font-XQ">MESSAGE</div>
          <form>
            <div class="form-row">
              <div class="col">
                <input type="text" class="form-control form-name" v-model="name" placeholder="姓名">
              </div>
              <div class="col">
                <input type="phone" class="form-control form-phone" v-model="phone" placeholder="电话">
              </div>
            </div>
            <div class="form-group mt-20">
              <textarea class="form-control form-contents" v-model="contents" placeholder="留言内容"></textarea>
            </div>
          </form>
          <div v-if="canSend" class="form-btn w-full text-whiteMike cursor-pointer" @click="submit" >提交</div>
          <div v-else class="form-btn w-full text-whiteMike cursor-pointer" @click="hint" >提交</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { message } from 'ant-design-vue'
export default {
  name: 'botFoot',
  data () {
    return {
      name: '',
      phone: '',
      contents: '',
      canSend: false
    }
  },
  props: {
    num: String,
  },
  mounted () {
  },
  watch: {
    phone (val) {
      // 验证手机号
      if (val.length === 11 && /^1[2-9]\d{9}$/.test(val)) {
        this.canSend = true
      } else {
        this.canSend = false
      }
    }
  },
  methods: {
    hint () {
      message.info({
        content: '请输入正确的手机号码',
        icon: ' ',
      })
    },
    async submit () {
      try {
        // 提交姓名、电话、留言内容
        if (!this.phone) {
          message.info({
            content: '请输入电话',
            icon: ' ',
          })
          return
        }
        // 手机号码验证
        if (!(/^1[23456789]\d{9}$/.test(this.phone))) {
          message.info({
            content: '请输入正确的手机号码',
            icon: ' ',
          })
          return
        }
        this.canSend = false
        let post = await this.$axios.post('/sendSmsText/sendSmsText', {
          phone: this.phone,
          name: this.name,
          contents: this.contents
        })
        if (post === 'success') {
          message.info({
            content: '提交成功',
            icon: ' ',
          })
        }
        this.close()
      } catch (error) {
        this.close()
        message.info({
          content: '提交失败',
          icon: ' ',
        })
      }
    },

    async close () {
      this.showLeave = false
      this.setEmpty()
    },
    async changeLeave () {
      this.showLeave = !this.showLeave
    },
    async setEmpty () {
      this.name = ''
      this.phone = ''
      this.contents = ''
    },
  }
}
</script>

<style lang='scss' scoped>
@import '@/assets/scss/mixin1700.scss';

.container-eight{
  height: 937px;
  @include hamburger {
    height: 380px;
  }
  background: url(../assets/images/bg08.png) no-repeat center center;
  background-size: cover;
  .container-center{
    padding: 180px 4vw 0 4vw !important;
    background: rgba(30, 69, 108, 0.8);
  }
  .form-title{
    @include hamburger {
      font-size: 18px;
    }
  }
  .form-subhead{
    @include hamburger {
      font-size: 12px;
    }
  }
  .form-subhead{
    margin-bottom: 70px;
    @include hamburger {
      margin-bottom: 20px;
    }
  }
  .form-control{
    color: #fff;  // 字体颜色
    background-color: rgba(252, 250, 240, 0.4); // input背景透明
    resize:none;
    &::-webkit-input-placeholder{
      color: #fff;  // 字体颜色
    }
  }
  .form-contents{
    height: 220px;
    @include hamburger {
      height: 110px;
    }
  }
  .form-btn{
    height: 54px;
    line-height: 54px;
    background: rgba(30, 69, 108, 0.8);
    font-size: 20px;
    margin-top: 40px;
    margin-bottom: 40px;
    @include hamburger {
      font-size: 14px;
      margin-top: 12px;
      margin-bottom: 10px;
    }
  }
}

</style>
