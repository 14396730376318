<template>
  <div class=" w">
    <div class="relative p-0 m-0">
      <steps class="pc-show container-left col-md-1" style="position:absolute" :num='num' title='加盟支持' color='#fff' ></steps>
      <div class="container-center h-full w-full p-0 m-0">
        <img class="pc-show wow zoomIn" src="../assets/images/support.png" alt="">
        <div class="mb-show">
          <steps :num='num' title='加盟支持' color='#fff' ></steps>
          <img class="wow zoomIn" src="../assets/images/support-mb.png" alt="">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'botFoot',
  data () {
    return {
    }
  },
  props: {
    num: String,
  },
  mounted () {
  },
  methods: {
  }
}
</script>

<style lang='scss' scoped>
@import '@/assets/scss/mixin1700.scss';

.container-center{
  background: url(../assets/images/bg07.png) no-repeat center top;
  background-size: cover;
  @include hamburger {
    padding-top: 0;
  }
  .container-center-con{
    // padding: 70px 100px;
    box-sizing: border-box;
  }
}

</style>
